import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import axios from "axios";

const app = createApp(App);

import router from "./router";
app.use(router);

import i18n from "./plugins/i18n/i18n";
app.use(i18n);

import { metaTagPlugin, updateOrCreateApiSchema } from "@/plugins/meta";
app.use(
  metaTagPlugin,
  {
    defaultName: "منصة دراية التعليمية",
    defaultLocale: i18n.global.locale.value,
    locales: ["ar", "en"],
    preconnect: [
      "https://derayah.net",
      "https://portal.derayah.net/signin",
      "https://portal.derayah.net/subscribe",
    ],
    textCallback: (text: string) => {
      return i18n.global.t(text);
    },
  },
  router
);

axios
  .get("/api/MetaTags/GetMeta")
  .then((response) => {
    app.config.globalProperties.$metaConfig = response.data;
    if (response.data.isValid) {
      updateOrCreateApiSchema(response.data.model);
    }
  })
  .catch((error) => {
    console.error(error);
  });
app.mount("#app");
