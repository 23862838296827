import router from "@/router";
import { setLocaleToUse } from "@/plugins/meta";

const ID_Language_KEY = "language" as string;

/**
 * @description get language form localStorage
 */
export const get = (): string => {
  return window.localStorage.getItem(ID_Language_KEY) ?? "ar";
};

/**
 * @description get language form localStorage
 */
export const getLanguage = (): any => {
  //return countries[get()];
  return get() == "en" ? countries.en : countries.ar;
};

/**
 * @description save language into localStorage
 * @param language: string
 */
export const save = (language: string): void => {
  window.localStorage.setItem(ID_Language_KEY, language);
};

/**
 * @description remove language form localStorage
 */
export const destroyLanguage = (): void => {
  window.localStorage.removeItem(ID_Language_KEY);
};

const html = document.querySelector("html") as HTMLElement;
const Body = document.querySelector("body") as HTMLElement;
export const change = (language: string): void => {
  save(language);
  SetDirection(language);
  changeLanguage(language);
};

export const SetDirection = (language?: string): void => {
  if (!language) {
    language = get();
  }
  html.setAttribute("lang", language);
  html.setAttribute("dir", language == "en" ? "ltr" : "rtl");
  Body.setAttribute("dir", language == "en" ? "ltr" : "rtl");
  setLocaleToUse(language);
};

const changeLanguage = (language: string) => {
  const currentRoute = router.currentRoute.value;
  const newUrl = `/${language}${currentRoute.fullPath.substr(3)}`;
  router.push(newUrl);
};

export const countries = {
  en: {
    flag: "/media/flags/united-states.svg",
    name: "English",
    lang: "en",
  },
  ar: {
    flag: "/media/flags/egypt.svg",
    name: "عربي",
    lang: "ar",
  },
};

export default {
  get,
  getLanguage,
  save,
  destroyLanguage,
  countries,
  change,
  SetDirection,
};
