// Arabic

export const locale = {
  home: "الرئيسية",
  menu: {
    home: "الرئيسية",
    modules: "الباقات و الخدمات",
    join: "انضم الى دراية",
    contact: "تواصل معنا",
    about: "من دراية",
    privacy: "سياسة الخصوصية",
    signIn: "تسجيل الدخول",
    subscribeNow: "اشترك الآن مجانا",
    registerNow: "سجل الان",
  },
  derayah: "درايــــــــة",
  educationalPlatform: "منصة تعليمية",
  ManagingAllAcademicProcesses: "إدارة جميع العمليات الأكاديمية",
  info: {
    1: "نظام تعليمي متكامل لإدارة المدارس حيث يوفر جميع الميزات المتطورة بالذكاء الاصطناعي المطلوبة لمدارس الإدارة الفعالة المتميزة في جميع أنحاء العالم خاصة الوطن العربي .",
  },
  subscription: "اشتراك مجاني",
  meta: {
    title: "منصة دراية التعليمية",
    description:
      "نظام تعليمي متكامل لإدارة المدارس حيث يوفر جميع الميزات المتطورة بالذكاء الاصطناعي المطلوبة لمدارس الإدارة الفعالة المتميزة في جميع أنحاء العالم خاصة الوطن العربي .",
    keywords:
      "منصة دراية,دراية التعليمية,منصة اختبارات,جدول المدرسة,المددرسة,مدرسة,تعليم,دراسة,جدول,منصة,ذكاء صناعى,تعلم الاطفال,حسابات,تعلم عن بعد",
  },
  noPayment: "لا يحتاج الى دفع او رسوم",
  joinDerayah: "انضم الي دراية",
  joinDerayah1: "انضم الي ",
  features: {
    header: "مميزات",
    header2: " منصة درايــــة ",
    studentManagement: "ادارة شئون الطلاب ",
    studentManagementDescription:
      " يتضمن أدوات متكاملة لتسجيل الطلاب بكل سهولة ويسر ونظام بحث متميز و تقارير و نماذج متطورة",
    studentManagementItem1: "الطلاب",
    studentManagementItem2: "التحويل و النقل ",
    studentManagementItem3: "التوزيع ",
    studentManagementItem4: "نماذج ",
    studentManagementItem5: "تقارير ",

    managementAndDistribution: "الإدارة والتوزيع",
    managementAndDistributionDescription:
      "فصول ذكية و احصائيات ونقل و توزيع الطلاب بشكل الي و اعدادات متعدده للتحكم الالي للنظام",
    managementAndDistributionItem1: "الفصول",
    managementAndDistributionItem2: "التوزيع",
    managementAndDistributionItem3: "المناهج الدراسية",

    humanResources: "ادارة الموارد البشرية ",
    humanResourcesDescription:
      " الموظفيين والاداريين والمعلمين وتقارير و نماذج و ارشف لكل المعاملات وحضور و انصراف وطلبات المواد",
    humanResourcesItem1: "الموظفين",
    humanResourcesItem2: "المدرسين",
    humanResourcesItem3: "الطلبات",
    humanResourcesItem4: "الحضور و الانصراف",

    schoolTimeTable: "الجدول المدرسي",
    schoolTimeTableDescription:
      "جدول يعمل بالذكاء الاصطناعي و يقوم بعمل افضل التوزيعات بشكل الي و جداول احتياطي و تنبية بالتغير",
    schoolTimeTableItem1: "الجدول",
    schoolTimeTableItem2: " اليوم الدراسي ",
    schoolTimeTableItem3: " المواد",
    schoolTimeTableItem4: " خطة التوزيع ",

    contentManagement: "ادارة المحتوي ",
    contentManagementDescription:
      "مكتبة الكترونية شاملة للطلاب و المدرسين و بها مشاهدة و استعارة للمكتبة ",
    contentManagementItem1: "المكتبة",
    contentManagementItem2: "اقسام و مؤلفين ",
    contentManagementItem3: " استعارة الكتب ",

    reception: "الاستقبال",
    receptionDescription:
      "تسجيل الزيارات و أرشيف المكالمات وادخال بيانات الزائر بسهولة مع إمكانية البحث و المراجعة أو تعديل بياناته",
    receptionItem1: "الزيارات",
    receptionItem2: "سجل المكالمات",
    receptionItem3: " ارقام الهواتف ",

    accounts: "الحسابات",
    accountsDescription:
      " تعمل بالذكاء الاصطناعي و بها كل متطلبات الحسابات المالية من مزانية عمومية و قائمة مركز مالي",
    accountsItem1: "قيود",
    accountsItem2: "سندات",
    accountsItem3: "الميزانية",
    accountsItem4: "ميزان",
    accountsItem5: "دفتر الاستاذ ",

    exams: "الاختبارات",
    examsDescription:
      "نظام اختيارات متكامل مدعم بالذكاء الاصطناعي للمساعدة على الانشاء و التصحيح الالي للاختيارات",
    examsItem1: "اختبارات",
    examsItem2: "انواع متعدده ",
    examsItem3: "بنك الاسئلة ",
    examsItem4: "تصحيح الي ",

    Settings: "الاعدادات",
    SettingsDescription:
      " اعدادات متقدمة تُتيح لك مميزات كثير للتحكم فى كل جزء من عملك",
    SettingsItem1: "البيانات",
    SettingsItem2: "التقارير و النماذج ",
    SettingsItem3: " الصلاحيات والمسؤليات ",
  },
  contact: {
    facebookTitle: "للتواصل و جديد دراية ",
    twitterTitle: " اخر الاخبار و الاضافات ",
    telegramTitle: "للتواصل و جديد دراية ",
    contactUs: "تواصل معنا",
    getIn: "ابقي علي",
    touch: "تواصل",
    touch1: "سنتواصل معك مرة أخرى بعد استلام طلبك خلال 24 ساعة",
    touch3: "How can we help you?",
    selected: {
      1: "الاشتراكات",
      2: "الدعم الفني",
    },
  },
};
