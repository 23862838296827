// USA

export const locale = {
  home: "Home",
  menu: {
    home: "Home",
    modules: "modules & services",
    join: "Join Derayah",
    contact: "contact Us",
    privacy: "Privacy Policy",
    about: "about",
    signIn: "Sign In",
    subscribeNow: "Subscribe now for free",
    registerNow: "Register Now",
  },
  subscription: "Subscribe now for free",
  derayah: "Derayah",
  educationalPlatform: "Educational Platform",
  ManagingAllAcademicProcesses: "Managing all academic processes",
  info: {
    1: "A comprehensive educational system for school management, providing advanced AI features required for effective and distinguished management in schools worldwide, especially in the Arab world.",
  },
  meta: {
    title: "Derayah educational platform",
    description:
      "نظام تعليمي متكامل لإدارة المدارس حيث يوفر جميع الميزات المتطورة بالذكاء الاصطناعي المطلوبة لمدارس الإدارة الفعالة المتميزة في جميع أنحاء العالم خاصة الوطن العربي .",
    keywords:
      "منصة دراية,دراية التعليمية,منصة اختبارات,جدول المدرسة,المددرسة,مدرسة,تعليم,دراسة,جدول,منصة,ذكاء صناعى,تعلم الاطفال,حسابات,تعلم عن بعد",
  },
  noPayment: "No payment or fees required",
  joinDerayah: "Join Derayah",
  joinDerayah1: "Join ",
  features: {
    header: "Features",
    header2: "Derayah Platform",

    studentManagement: "Student Management",
    studentManagementDescription:
      "Includes integrated tools for easy student registration, advanced search system, reports, and advanced forms.",
    studentManagementItem1: "Students",
    studentManagementItem2: "Transfers and Mobility",
    studentManagementItem3: "Distribution",
    studentManagementItem4: "Forms",
    studentManagementItem5: "Reports",

    managementAndDistribution: "Management and Distribution",
    managementAndDistributionDescription:
      "Smart classes, statistics, automated student transfer and distribution, multiple settings for system automation control.",
    managementAndDistributionItem1: "Classes",
    managementAndDistributionItem2: "Distribution",
    managementAndDistributionItem3: "Curriculum",

    humanResources: "Human Resources Management",
    humanResourcesDescription:
      "Employees, administrators, teachers, reports, forms, and archiving for all transactions, attendance, leave requests.",
    humanResourcesItem1: "Employees",
    humanResourcesItem2: "Teachers",
    humanResourcesItem3: "Requests",
    humanResourcesItem4: "Attendance and Leave",

    schoolTimeTable: "School Timetable",
    schoolTimeTableDescription:
      "An AI-powered timetable that generates optimal schedules automatically, backs up timetables, and changes notifications.",
    schoolTimeTableItem1: "Timetable",
    schoolTimeTableItem2: "School Day",
    schoolTimeTableItem3: "Subjects",
    schoolTimeTableItem4: "Distribution Plan",

    contentManagement: "Content Management",
    contentManagementDescription:
      "Comprehensive electronic library for students and teachers, with browsing and borrowing capabilities.",
    contentManagementItem1: "Library",
    contentManagementItem2: "Sections and Authors",
    contentManagementItem3: "Book Borrowing",

    reception: "Reception",
    receptionDescription:
      "Easy visitor registration, call archives, and easy data entry with search, review, and modification capabilities.",
    receptionItem1: "Visits",
    receptionItem2: "Call Log",
    receptionItem3: "Phone Numbers",

    accounts: "Accounts",
    accountsDescription:
      "AI-powered accounting system with all financial requirements, including general budget and financial center list.",
    accountsItem1: "Entries",
    accountsItem2: "Vouchers",
    accountsItem3: "Budget",
    accountsItem4: "Balance",
    accountsItem5: "General Ledger",

    exams: "Exams",
    examsDescription:
      "Comprehensive examination system supported by artificial intelligence for creation and automatic correction of multiple-choice exams.",
    examsItem1: "Exams",
    examsItem2: "Multiple Types",
    examsItem3: "Question Bank",
    examsItem4: "Automatic Correction",

    Settings: "Settings",
    SettingsDescription:
      "Advanced settings that provide many features to control every aspect of your work.",
    SettingsItem1: "Data",
    SettingsItem2: "Reports and Forms",
    SettingsItem3: "Permissions and Responsibilities",
  },

  contact: {
    facebookTitle: "Communication and new knowledge",
    twitterTitle: "Latest news and additions",
    telegramTitle: "Communication and new Features ",
    contactUs: "Contact us",
    getIn: "Get In",
    touch: "Touch",
    touch1: "We will contact again after receive your request in 24h",
    touch2: "The field is required mark as *",
    touch3: "How can we help you?",
    selected: {
      1: "Subscriptions",
      2: "Technical support",
    },
  },
};
