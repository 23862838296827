import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/Home/index.vue";
import ContractUs from "../views/ContactUs/index.vue";
import Privacy from "../views/Privacy/index.vue";
const defaultLanguage: string = window.localStorage.getItem("language") ?? "ar";
import LanguageService from "@/plugins/i18n/language";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: `/${defaultLanguage}`,
    meta: {
      title: "اتصل بنا",
      description: "تواصل مع منصة دراية",
    },
  },
  {
    path: "/:lang(ar|en)",
    alias: "/",
    component: () => import("@/views/shared/index.vue"),
    // meta: {
    //   title: "منصة دراية التعليمية",
    //   description: "meta.description",
    //   image: "/assets/logo/android-chrome-192x192.png",
    //   locale: defaultLanguage,
    //   keywords: "meta.keywords",
    //   metaTags: {
    //     "og:locale": defaultLanguage,
    //     "og:type": "article",
    //     "og:title": "meta.title",
    //     "og:description": "meta.description",
    //     "og:site_name": "meta.title",
    //     "twitter:card": "summary_large_image",
    //     "twitter:title": "meta.title",
    //     "twitter:description": "meta.description",
    //     "apple-touch-icon": "/assets/logo/android-chrome-192x192.png",
    //   },
    // },
    children: [
      {
        path: "",
        alias: "home",
        name: "home",
        component: HomeView,
      },
      {
        path: "contact",
        name: "contact",
        component: ContractUs,
        meta: {
          title: "اتصل بنا",
          description: "تواصل مع منصة دراية",
        },
      },
      {
        path: "privacy",
        name: "privacy",
        component: Privacy,
        meta: {
          title: "سياسة الخصوصية",
          description: "سياسة الخصوصية",
        },
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const lang = to.path.toString().split("/")[1],
    localLanguage = LanguageService.get();

  if (lang == null || (lang != "ar" && lang != "en")) {
    next("/" + defaultLanguage + to.path.toString());
  } else {
    if (lang != localLanguage) {
      LanguageService.change(lang);
    }

    next();
    // Scroll page to top on every route change
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  next();
});

export default router;
