
import { defineComponent, defineAsyncComponent } from "vue";

export default defineComponent({
  name: "IndexPage",
  components: {
    Header: defineAsyncComponent(() => import("./Partial/header.vue")),
    featuresInfo: defineAsyncComponent(
      () => import("./Partial/featuresInfo.vue")
    ),
    CompCommunity: defineAsyncComponent(
      () => import("./Partial/CompCommunity.vue")
    ),
  },
  setup() {
    return {};
  },
});
