import { createI18n } from "vue-i18n";
import { locale as en } from "./en";
import { locale as ar } from "./ar";
import LanguageService from "./language";

const messages = {
  en,
  ar,
};
const i18n = createI18n({
  legacy: false,
  locale: LanguageService.get(),
  globalInjection: true,
  messages,
});

export default i18n;
