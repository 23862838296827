
import { defineComponent, onMounted } from "vue";
import LanguageService from "@/plugins/i18n/language";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "app",
  setup() {
    const i18n = useI18n();
    i18n.locale.value = LanguageService.get();

    let Direction = LanguageService.get() == "en" ? "ltr" : "rtl";

    onMounted(() => {
      LanguageService.SetDirection();
    });
    return {
      Direction,
    };
  },
});
