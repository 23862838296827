
import { defineComponent, reactive, ref } from "vue";
import axios from "axios";
export default defineComponent({
  name: "IndexPage",
  setup() {
    const data = reactive({
      name: "",
      email: "",
      title: "",
      content: "",
    });
    const isNotValid = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(data.email) && data.email)
        return "Email is not Valid";
      if (!data.name) return "Name is required";
      if (!data.email) return "Email is required";
      if (!data.title) return "Title is required";
      if (!data.content) return "Content is required";
      return null;
    };

    const send = () => {
      if (!isNotValid()) {
        axios
          .post("/api/ContactUs/AddOrEdit", data)
          .then((response: any) => {
            console.log(response.data.messages);
            alert(response.data.messages[0]?.message);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };

    return { data, send, isNotValid };
  },
});
